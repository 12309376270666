import { useState } from 'react';
import * as S from './styles';
import { ChevronDown16, Tag32 } from '@carbon/icons-react';
import { useDropdownCategoriasBanner } from '../../hook/useDropDownCategoriasBanner';
import { TagManagerUtils, ActionType, UserLoggedStatus } from '../../TagManagerUtils';
import { removeAccents } from '../../utils/normalizeString';

interface ICategory {
    id: string;
    url_path: string;
    url_key: string;
    path: string;
}

const MenuCategories = ({
    categoriesData,
    isFromHome,
}: {
    categoriesData: any;
    isFromHome?: boolean;
}) => {
    const { data } = useDropdownCategoriasBanner();
    const [isVisible, setIsVisible] = useState(false);
    const [isSubCategsHovered, setIsSubCategsHovered] = useState('');

    const changePage = (item: ICategory) => {
        window.location.href = process.env.REACT_APP_PUBLIC_URL + `loja/${item?.url_path}`;
    };

    const handleOnMouseOver = (itemName: string) => {
        setIsSubCategsHovered(itemName);
    };

    const sumOfCategoriesAndSubCategories = categoriesData?.reduce(
        (sum: any, child: { children: string | any[] }) => sum + child?.children?.length,
        0,
    );

    function splitCategoriesIntoGroups(categories: never[]) {
        const categ = categories || [];
        const numberOfColumns = sumOfCategoriesAndSubCategories > 24 ? 3 : 2;
        const maxItemsPerGroup = 12;

        const groups = Array.from({ length: numberOfColumns }, () => []);

        function totalItemsInGroup(group: any[]) {
            return group.reduce((sum, category) => sum + 1 + (category?.children?.length || 0), 0);
        }

        const orderedCategs = (categ as { children?: any[] }[])
            .slice()
            .sort((a, b) => (b?.children?.length || 0) - (a?.children?.length || 0));

        for (const category of orderedCategs) {
            let added = false;

            for (let columnIndex = 0; columnIndex < numberOfColumns; columnIndex++) {
                const currentGroup = groups[columnIndex];
                const totalInGroup = totalItemsInGroup(currentGroup);

                if (totalInGroup + 1 + (category?.children?.length || 0) <= maxItemsPerGroup) {
                    currentGroup.push(category as never);
                    added = true;
                    break;
                }
            }

            if (!added) {
                const columnIndex = groups.reduce((minIndex, currentGroup, currentIndex) => {
                    const totalInGroup = totalItemsInGroup(currentGroup);
                    const minTotal =
                        sumOfCategoriesAndSubCategories >= 37
                            ? totalItemsInGroup(groups[minIndex])
                            : totalItemsInGroup(groups[minIndex]) - totalInGroup;

                    return totalInGroup < minTotal ? currentIndex : minIndex;
                }, 0);
                groups[columnIndex].push(category as never);
            }
        }

        return groups;
    }

    const filteredAndDividedCategories =
        (categoriesData && splitCategoriesIntoGroups(categoriesData)) || [];

    return (
        <>
            {sumOfCategoriesAndSubCategories > 24 ? (
                <S.DropBigSize>
                    <S.Dropdown>
                        <S.TextHeaderContainer
                            onMouseOver={() => {
                                if (categoriesData) {
                                    setIsVisible(true);
                                }
                            }}
                            onMouseLeave={() => {
                                setIsVisible(false);
                            }}
                        >
                            <S.CategAndUpContainer>
                                {/* <Tag32 className="categorias" /> */}
                                <p className="categorias">
                                    {isFromHome ? 'Produtos da loja' : 'Categorias'}
                                </p>

                                <ChevronDown16
                                    className={isVisible ? 'chevronDown-up' : 'chevronDown'}
                                />
                            </S.CategAndUpContainer>

                            <S.SeparatorBelowCategsText
                                id={isVisible ? 'separator' : 'separator-hidden'}
                            />
                        </S.TextHeaderContainer>

                        <S.DropdownContent show={isVisible}>
                            <nav
                                className="content-three-columns"
                                onMouseOver={() => {
                                    if (categoriesData) {
                                        setIsVisible(true);
                                    }
                                }}
                                onMouseLeave={() => {
                                    setIsVisible(false);
                                }}
                            >
                                <div className="text-container">
                                    <S.CategeoryColumn>
                                        {categoriesData &&
                                            filteredAndDividedCategories[0]?.map((item: any) => {
                                                const sortedSubCategories = item?.children
                                                    ?.slice()
                                                    ?.sort((a: any, b: any) =>
                                                        a?.name?.localeCompare(b?.name),
                                                    );

                                                sortedSubCategories?.forEach(
                                                    (c: any, index: any) => {
                                                        if (c.name === 'Outros') {
                                                            sortedSubCategories.splice(index, 1);
                                                            sortedSubCategories.push(c);
                                                        }
                                                    },
                                                );

                                                return (
                                                    <>
                                                        {item?.children?.length > 0 && (
                                                            <div className="title-grid">
                                                                <S.CategTitle id="title">
                                                                    {item?.name}
                                                                </S.CategTitle>

                                                                <S.List>
                                                                    {sortedSubCategories?.map(
                                                                        (
                                                                            itemN2: any,
                                                                            index: any,
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    className={
                                                                                        isSubCategsHovered ===
                                                                                        itemN2?.id
                                                                                            ? 'hovered'
                                                                                            : 'not-hovered'
                                                                                    }
                                                                                    id={
                                                                                        itemN2?.name
                                                                                            ?.length >=
                                                                                        25
                                                                                            ? 'long-text-hovered'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <S.SubCategText
                                                                                        key={
                                                                                            itemN2?.id
                                                                                        }
                                                                                        className={
                                                                                            index ===
                                                                                            0
                                                                                                ? 'first'
                                                                                                : ''
                                                                                        }
                                                                                        id={
                                                                                            itemN2
                                                                                                ?.name
                                                                                                ?.length >=
                                                                                            25
                                                                                                ? 'long-text'
                                                                                                : ''
                                                                                        }
                                                                                    >
                                                                                        <S.LinkWithIcon
                                                                                            title={
                                                                                                itemN2?.name
                                                                                            }
                                                                                            href={`/loja/${itemN2?.url_path}`}
                                                                                            onMouseOver={() => {
                                                                                                handleOnMouseOver(
                                                                                                    itemN2?.id,
                                                                                                );
                                                                                            }}
                                                                                            onMouseOut={() => {
                                                                                                handleOnMouseOver(
                                                                                                    '',
                                                                                                );
                                                                                            }}
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                setIsVisible(
                                                                                                    false,
                                                                                                );

                                                                                                TagManagerUtils.interact(
                                                                                                    ActionType.Click,
                                                                                                    'combobox',
                                                                                                    `${removeAccents(
                                                                                                        itemN2?.name,
                                                                                                    )}`,
                                                                                                    'produtos',
                                                                                                    UserLoggedStatus.LoggedOut,
                                                                                                    `e-agro${location?.pathname}`,
                                                                                                );
                                                                                                changePage(
                                                                                                    itemN2,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                itemN2?.name
                                                                                            }
                                                                                        </S.LinkWithIcon>
                                                                                    </S.SubCategText>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                </S.List>
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                    </S.CategeoryColumn>

                                    <S.CategeoryColumn>
                                        {categoriesData &&
                                            filteredAndDividedCategories[1]?.map((item: any) => {
                                                const sortedSubCategories = item?.children
                                                    ?.slice()
                                                    ?.sort((a: any, b: any) =>
                                                        a?.name?.localeCompare(b?.name),
                                                    );

                                                sortedSubCategories?.forEach(
                                                    (c: any, index: any) => {
                                                        if (c.name === 'Outros') {
                                                            sortedSubCategories.splice(index, 1);
                                                            sortedSubCategories.push(c);
                                                        }
                                                    },
                                                );

                                                return (
                                                    <>
                                                        {item?.children?.length > 0 && (
                                                            <div className="title-grid">
                                                                <S.CategTitle id="title">
                                                                    {item?.name}
                                                                </S.CategTitle>

                                                                <S.List>
                                                                    {sortedSubCategories?.map(
                                                                        (
                                                                            itemN2: any,
                                                                            index: any,
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    className={
                                                                                        isSubCategsHovered ===
                                                                                        itemN2?.id
                                                                                            ? 'hovered'
                                                                                            : 'not-hovered'
                                                                                    }
                                                                                    id={
                                                                                        itemN2?.name
                                                                                            ?.length >=
                                                                                        25
                                                                                            ? 'long-text-hovered'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <S.SubCategText
                                                                                        key={
                                                                                            itemN2?.id
                                                                                        }
                                                                                        className={
                                                                                            index ===
                                                                                            0
                                                                                                ? 'first'
                                                                                                : ''
                                                                                        }
                                                                                        id={
                                                                                            itemN2
                                                                                                ?.name
                                                                                                ?.length >=
                                                                                            25
                                                                                                ? 'long-text'
                                                                                                : ''
                                                                                        }
                                                                                    >
                                                                                        <S.LinkWithIcon
                                                                                            title={
                                                                                                itemN2?.name
                                                                                            }
                                                                                            href={`/loja/${itemN2?.url_path}`}
                                                                                            onMouseOver={() => {
                                                                                                handleOnMouseOver(
                                                                                                    itemN2?.id,
                                                                                                );
                                                                                            }}
                                                                                            onMouseOut={() => {
                                                                                                handleOnMouseOver(
                                                                                                    '',
                                                                                                );
                                                                                            }}
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                setIsVisible(
                                                                                                    false,
                                                                                                );
                                                                                                TagManagerUtils.interact(
                                                                                                    ActionType.Click,
                                                                                                    'combobox',
                                                                                                    `${removeAccents(
                                                                                                        itemN2?.name,
                                                                                                    )}`,
                                                                                                    'produtos',
                                                                                                    UserLoggedStatus.LoggedOut,
                                                                                                    `e-agro${location?.pathname}`,
                                                                                                );
                                                                                                changePage(
                                                                                                    itemN2,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                itemN2?.name
                                                                                            }
                                                                                        </S.LinkWithIcon>
                                                                                    </S.SubCategText>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                </S.List>
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                    </S.CategeoryColumn>

                                    <S.CategeoryColumn>
                                        {categoriesData &&
                                            filteredAndDividedCategories[2]?.map((item: any) => {
                                                const sortedSubCategories = item?.children
                                                    ?.slice()
                                                    ?.sort((a: any, b: any) =>
                                                        a?.name?.localeCompare(b?.name),
                                                    );

                                                sortedSubCategories?.forEach(
                                                    (c: any, index: any) => {
                                                        if (c.name === 'Outros') {
                                                            sortedSubCategories.splice(index, 1);
                                                            sortedSubCategories.push(c);
                                                        }
                                                    },
                                                );

                                                return (
                                                    <>
                                                        {item?.children?.length > 0 && (
                                                            <div className="title-grid">
                                                                <S.CategTitle id="title">
                                                                    {item?.name}
                                                                </S.CategTitle>

                                                                <S.List>
                                                                    {sortedSubCategories?.map(
                                                                        (
                                                                            itemN2: any,
                                                                            index: any,
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    className={
                                                                                        isSubCategsHovered ===
                                                                                        itemN2?.id
                                                                                            ? 'hovered'
                                                                                            : 'not-hovered'
                                                                                    }
                                                                                    key={index}
                                                                                    id={
                                                                                        itemN2?.name
                                                                                            ?.length >=
                                                                                        25
                                                                                            ? 'long-text-hovered'
                                                                                            : ''
                                                                                    }
                                                                                >
                                                                                    <S.SubCategText
                                                                                        key={
                                                                                            itemN2?.id
                                                                                        }
                                                                                        className={
                                                                                            index ===
                                                                                            0
                                                                                                ? 'first'
                                                                                                : ''
                                                                                        }
                                                                                        id={
                                                                                            itemN2
                                                                                                ?.name
                                                                                                ?.length >=
                                                                                            25
                                                                                                ? 'long-text'
                                                                                                : ''
                                                                                        }
                                                                                    >
                                                                                        <S.LinkWithIcon
                                                                                            title={
                                                                                                itemN2?.name
                                                                                            }
                                                                                            href={`/loja/${itemN2?.url_path}`}
                                                                                            onMouseOver={() => {
                                                                                                handleOnMouseOver(
                                                                                                    itemN2?.id,
                                                                                                );
                                                                                            }}
                                                                                            onMouseOut={() => {
                                                                                                handleOnMouseOver(
                                                                                                    '',
                                                                                                );
                                                                                            }}
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                setIsVisible(
                                                                                                    false,
                                                                                                );
                                                                                                TagManagerUtils.interact(
                                                                                                    ActionType.Click,
                                                                                                    'combobox',
                                                                                                    `${removeAccents(
                                                                                                        itemN2?.name,
                                                                                                    )}`,
                                                                                                    'produtos',
                                                                                                    UserLoggedStatus.LoggedOut,
                                                                                                    `e-agro${location?.pathname}`,
                                                                                                );
                                                                                                changePage(
                                                                                                    itemN2,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                itemN2?.name
                                                                                            }
                                                                                        </S.LinkWithIcon>
                                                                                    </S.SubCategText>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                </S.List>
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                    </S.CategeoryColumn>
                                </div>

                                <div className="separator"></div>

                                <a
                                    title={data?.getMenuMarketplace?.title}
                                    href={data?.getMenuMarketplace?.a}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(data?.getMenuMarketplace?.link);
                                    }}
                                >
                                    <S.Banner
                                        src={data?.getMenuMarketplace?.image_url}
                                        alt={data?.getMenuMarketplace?.alt}
                                        title={data?.getMenuMarketplace?.title}
                                    />
                                </a>
                            </nav>

                            <div
                                className="overlay"
                                onMouseOver={() => {
                                    setIsVisible(false);
                                }}
                            />
                        </S.DropdownContent>
                    </S.Dropdown>
                </S.DropBigSize>
            ) : (
                <S.Drop>
                    <S.Dropdown>
                        <S.TextHeaderContainer
                            onMouseOver={() => {
                                if (categoriesData) {
                                    setIsVisible(true);
                                }
                            }}
                            onMouseLeave={() => {
                                setIsVisible(false);
                            }}
                        >
                            <S.CategAndUpContainer>
                                {/* <Tag32 className="categorias" /> */}
                                <p className="categorias">
                                    {isFromHome ? 'Produtos da loja' : 'Categorias'}
                                </p>
                                {/* Aqui voce pode chamar o botao de categorias do seu navbar */}
                                <ChevronDown16
                                    className={isVisible ? 'chevronDown-up' : 'chevronDown'}
                                />
                            </S.CategAndUpContainer>

                            <S.SeparatorBelowCategsText
                                id={isVisible ? 'separator' : 'separator-hidden'}
                            />
                        </S.TextHeaderContainer>

                        {
                            <S.DropdownContent show={isVisible}>
                                <nav
                                    className="content"
                                    onMouseOver={() => {
                                        if (categoriesData) {
                                            setIsVisible(true);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        setIsVisible(false);
                                    }}
                                >
                                    <div
                                        className={
                                            // eslint-disable-next-line no-constant-condition
                                            12 <= 17 ? 'low-size-container-to-txts' : undefined
                                        }
                                    >
                                        <div
                                            className={
                                                // eslint-disable-next-line no-constant-condition
                                                12 <= 17
                                                    ? 'text-container-low-size'
                                                    : 'text-container'
                                            }
                                            // eslint-disable-next-line no-constant-condition
                                            id={12 <= 17 ? 'text-container-low-size' : undefined}
                                        >
                                            <S.CategeoryColumn>
                                                {categoriesData &&
                                                    filteredAndDividedCategories[0]?.map(
                                                        (item: any) => {
                                                            const sortedSubCategories =
                                                                item?.children
                                                                    ?.slice()
                                                                    ?.sort((a: any, b: any) =>
                                                                        a?.name?.localeCompare(
                                                                            b?.name,
                                                                        ),
                                                                    );
                                                            return (
                                                                <>
                                                                    {item?.children?.length > 0 && (
                                                                        <div className="title-grid">
                                                                            <S.CategTitle id="title">
                                                                                {item?.name}
                                                                            </S.CategTitle>

                                                                            <S.List>
                                                                                {sortedSubCategories.map(
                                                                                    (
                                                                                        itemN2: any,
                                                                                        index: any,
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={
                                                                                                    isSubCategsHovered ===
                                                                                                    itemN2?.id
                                                                                                        ? 'hovered'
                                                                                                        : 'not-hovered'
                                                                                                }
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                id={
                                                                                                    itemN2
                                                                                                        ?.name
                                                                                                        ?.length >=
                                                                                                    25
                                                                                                        ? 'long-text-hovered'
                                                                                                        : ''
                                                                                                }
                                                                                            >
                                                                                                <S.SubCategText
                                                                                                    key={
                                                                                                        itemN2?.id
                                                                                                    }
                                                                                                    className={
                                                                                                        index ===
                                                                                                        0
                                                                                                            ? 'first'
                                                                                                            : ''
                                                                                                    }
                                                                                                    id={
                                                                                                        itemN2
                                                                                                            ?.name
                                                                                                            ?.length >=
                                                                                                        25
                                                                                                            ? 'long-text'
                                                                                                            : ''
                                                                                                    }
                                                                                                >
                                                                                                    <S.LinkWithIcon
                                                                                                        title={
                                                                                                            itemN2?.name
                                                                                                        }
                                                                                                        href={`/loja/${itemN2?.url_path}`}
                                                                                                        onMouseOver={() => {
                                                                                                            handleOnMouseOver(
                                                                                                                itemN2?.id,
                                                                                                            );
                                                                                                        }}
                                                                                                        onMouseOut={() => {
                                                                                                            handleOnMouseOver(
                                                                                                                '',
                                                                                                            );
                                                                                                        }}
                                                                                                        onClick={(
                                                                                                            e,
                                                                                                        ) => {
                                                                                                            e.preventDefault();
                                                                                                            setIsVisible(
                                                                                                                false,
                                                                                                            );
                                                                                                            TagManagerUtils.interact(
                                                                                                                ActionType.Click,
                                                                                                                'combobox',
                                                                                                                `${removeAccents(
                                                                                                                    itemN2?.name,
                                                                                                                )}`,
                                                                                                                'produtos',
                                                                                                                UserLoggedStatus.LoggedOut,
                                                                                                                `e-agro${location?.pathname}`,
                                                                                                            );
                                                                                                            changePage(
                                                                                                                itemN2,
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            itemN2?.name
                                                                                                        }
                                                                                                    </S.LinkWithIcon>
                                                                                                </S.SubCategText>
                                                                                            </div>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                            </S.List>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            );
                                                        },
                                                    )}
                                            </S.CategeoryColumn>

                                            <S.CategeoryColumn>
                                                {categoriesData &&
                                                    filteredAndDividedCategories[1]?.map(
                                                        (item: any) => {
                                                            const sortedSubCategories =
                                                                item?.children
                                                                    ?.slice()
                                                                    ?.sort((a: any, b: any) =>
                                                                        a?.name?.localeCompare(
                                                                            b?.name,
                                                                        ),
                                                                    );
                                                            return (
                                                                <>
                                                                    {item?.children?.length > 0 && (
                                                                        <div className="title-grid">
                                                                            <S.CategTitle id="title">
                                                                                {item?.name}
                                                                            </S.CategTitle>

                                                                            <S.List>
                                                                                {sortedSubCategories.map(
                                                                                    (
                                                                                        itemN2: any,
                                                                                        index: any,
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={
                                                                                                    isSubCategsHovered ===
                                                                                                    itemN2?.id
                                                                                                        ? 'hovered'
                                                                                                        : 'not-hovered'
                                                                                                }
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                id={
                                                                                                    itemN2
                                                                                                        ?.name
                                                                                                        ?.length >=
                                                                                                    25
                                                                                                        ? 'long-text-hovered'
                                                                                                        : ''
                                                                                                }
                                                                                            >
                                                                                                <S.SubCategText
                                                                                                    key={
                                                                                                        itemN2?.id
                                                                                                    }
                                                                                                    className={
                                                                                                        index ===
                                                                                                        0
                                                                                                            ? 'first'
                                                                                                            : ''
                                                                                                    }
                                                                                                    id={
                                                                                                        itemN2
                                                                                                            ?.name
                                                                                                            ?.length >=
                                                                                                        25
                                                                                                            ? 'long-text'
                                                                                                            : ''
                                                                                                    }
                                                                                                >
                                                                                                    <S.LinkWithIcon
                                                                                                        title={
                                                                                                            itemN2?.name
                                                                                                        }
                                                                                                        href={`/loja/${itemN2?.url_path}`}
                                                                                                        onMouseOver={() => {
                                                                                                            handleOnMouseOver(
                                                                                                                itemN2?.id,
                                                                                                            );
                                                                                                        }}
                                                                                                        onMouseOut={() => {
                                                                                                            handleOnMouseOver(
                                                                                                                '',
                                                                                                            );
                                                                                                        }}
                                                                                                        onClick={(
                                                                                                            e,
                                                                                                        ) => {
                                                                                                            e.preventDefault();
                                                                                                            setIsVisible(
                                                                                                                false,
                                                                                                            );
                                                                                                            TagManagerUtils.interact(
                                                                                                                ActionType.Click,
                                                                                                                'combobox',
                                                                                                                `${removeAccents(
                                                                                                                    itemN2?.name,
                                                                                                                )}`,
                                                                                                                'produtos',
                                                                                                                UserLoggedStatus.LoggedOut,
                                                                                                                `e-agro${location?.pathname}`,
                                                                                                            );
                                                                                                            changePage(
                                                                                                                itemN2,
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            itemN2?.name
                                                                                                        }
                                                                                                    </S.LinkWithIcon>
                                                                                                </S.SubCategText>
                                                                                            </div>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                            </S.List>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            );
                                                        },
                                                    )}
                                            </S.CategeoryColumn>
                                        </div>
                                    </div>

                                    <div className="separator"></div>

                                    <a
                                        title={data?.getMenuMarketplace?.title}
                                        href={data?.getMenuMarketplace?.link}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(data?.getMenuMarketplace?.link);
                                        }}
                                    >
                                        <S.Banner
                                            style={{
                                                marginRight:
                                                    sumOfCategoriesAndSubCategories <= 17
                                                        ? '32px'
                                                        : '30px',
                                            }}
                                            src={data?.getMenuMarketplace?.image_url}
                                            alt={data?.getMenuMarketplace?.alt}
                                            title={data?.getMenuMarketplace?.title}
                                        />
                                    </a>
                                </nav>

                                <div
                                    className="overlay"
                                    onMouseOver={() => {
                                        setIsVisible(false);
                                    }}
                                />
                            </S.DropdownContent>
                        }
                    </S.Dropdown>
                </S.Drop>
            )}
        </>
    );
};
export default MenuCategories;

//
